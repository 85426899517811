.about__section-item {
  column-gap: 3rem;
}

.about__section-item p i {
  color: #f9a826;
  font-size: 1.1rem;
}

@media only screen and (max-width: 992px) {
  .about__section-content h2 {
    font-size: 1.5rem;
  }
  .about__section-item {
    column-gap: 3rem;
  }
  .about__section-item p {
    font-size: 0.9rem;
    margin-bottom: 0;
  }

  .section__description i {
    font-size: 0.9rem !important;
   
  }
}

@media only screen and (max-width: 768px) {
  .about__img {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 576px) {
  .about__section {
    margin-top: 280px;
  }
}


.steps-container {
  display: flex;
  flex-wrap: wrap;
  gap: 45px;
  justify-content: center;
  padding: 0;

}
.step-card {
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s, box-shadow 0.3s;
  width: 220px;
  text-align: center;
  padding:15px 20px ;
  cursor: pointer;
  font-size: 0.95rem;
  line-height: 30px;
}
.step-card:hover {
  transform: scale(1.1);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.step-icon {
  font-size: 40px;
  color: #f9a826;
  margin-bottom: 15px;


}
.step-title {
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: bold;
 
  color: #000d6b;
}
.step-card p {
  font-size: 16px;
  color: #666;
  font-size: 0.95rem;
  line-height: 30px;
}


.faq-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  
}

.faq-item {
  border-bottom: 1px solid #ddd;
}


.form__label {
  display: block; /* Ensures labels are on their own line */
 /* Adds space between the label and input */
  font-weight: 500; /* Makes the label text bold */
  font-size: 1rem; /* Sets the font size */
  color: #7c8a97; /* Sets the color of the label text */
}

/* Optional: Style for the inputs if needed */

input[type="datetime-local"] {
  width: 100%; /* Makes the input take up full width of the container */
  padding: 0.5rem; /* Adds padding inside the input */
  border: 1px solid #ccc; /* Sets a border for the input */
  border-radius: 4px; /* Rounds the corners of the input */
  font-size: 1rem;
   
}

.faq-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  color: #000d6b;
  cursor: pointer;
  transition: background-color 0.3s;
}


.faq-question {
  font-size: 1.1rem;
  font-weight: 600;
}

.faq-toggle-icon i {
  font-size: 1.1rem;
  color: #333;
}

.faq-answer {
  padding: 0 15px;
  max-height: 0;
  overflow: hidden;
  font-size: 1rem;
  color: #818f9b;
  border-top: 1px solid #ddd;

}

.faq-answer.open {
  padding: 15px;
  /* background-color: #f1f1f1; */
  max-height: 500px; /* Adjust to fit the maximum height you expect */
  font-size: 0.95rem;
  line-height: 30px;
}
