.not-found {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    font-size: 2rem;
    color: #333;
    text-align: center;
    background-color: #f8f9fa;
  }
  