@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
}

main {
  width: 100%;
  height: 100vh;
  background-color: #fff;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.box {
  position: relative;
  width: 100%;
  max-width: 1020px;
  height: 640px;
  background-color: #fff;
  border-radius: 3.3rem;
  box-shadow: 20px 60px 40px 10px rgba(0, 0, 0, 0.27);
  border:2px  solid #000c6b
}

.forms-wrap {
  position: absolute;
  height: 100%;
  width: 45%;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}

.froms {
  max-width: 260px;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  width: 27px;
  margin-right: 0.3rem;
}

.logo h4 {
  font-size: 1.1rem;
  margin-top: -9px;
  letter-spacing: -0.5px;
  color: #000d6b;
}

.heading h2 {
  font-size: 2.1rem;
  font-weight: 600;
  color: #000d6b;
}

.heading h6 {
  color: #000d6b;
  font-weight: 400;
  font-size: 0.75rem;
  display: inline;
}

.toggle {
  color: #151111;
  text-decoration: none;
  font-size: 0.75rem;
  font-weight: 500;
}

.input-wrap {
  position: relative;
  height: 37px;
  margin-bottom: 2rem;
}

.input-field {
  position: absolute;
  width: 100%;
  height: 100%;
  background: none;
  border: none;
  outline: none;
  border-bottom: 1px solid #bbb;
  padding: 0;
  font-size: 0.95rem;
  color: #151111;
}

.label {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.95rem;
  color: #bbb;
  transition: all 0.3s ease; /* Smooth transition for label movement */
  pointer-events: none;
}

.input-field:focus + label,
.input-field:not(:placeholder-shown) + label {
  font-size: 0.75rem;
  top: -2px;
  color: #151111;
}

.sign-btn {
  display: inline-block;
  width: 100%;
  height: 43px;
  background-color: #f9a826;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 0.8rem;
  font-size: 0.8rem;
  margin-bottom: 2rem;
}

.heading a {
  color: #f09809; /* Dark blue color */
  text-decoration: none; /* Remove underline */
  font-weight: 500; /* Make the link bold */
  transition: color 0.3s; /* Smooth color transition on hover */
}

.heading a:hover {
  color: #f09809; /* Change color on hover */
}

.sign-btn:hover {
  background-color: #f09809;
}

.text {
  color: #000d6b;
  font-size: 0.7rem;
}

.text a {
  color: #f09809;
  transition: 0.3s;
}

.text a:hover {
  color: #f9a826;
}

.car-rental-info {
  display: none; /* Hidden by default */
  width: 50%;
  padding: 2rem;

  position: absolute;
  right: 7%;
  top: 50%; /* Center vertically */
  transform: translateY(-50%); /* Adjust vertical centering */
}
.car-rental-info h3 {
  font-size: 1.5rem;
  color: #000d6b;
  margin-bottom: 1rem;
}

.car-rental-info p {
  font-size: 1rem;
  color: #333;
}
.car-rental-image {
  width: 100%;
  height: auto;
  border-radius: 0.5rem; /* Optional: Rounded corners for the image */
  margin-bottom: 1rem; /* Space between image and text */
}
@media (min-width: 851px) {
  .car-rental-info {
    display: block; /* Show on large screens */
  }
}

@media (max-width: 850px) {
  .box {
    height: auto;
    max-width: 550px;
    overflow: hidden;
  }

  .inner-box {
    position: static;
    transform: none;
    width: revert;
    height: revert;
    padding: 2rem;
  }

  .forms-wrap {
    position: revert;
    width: 100%;
    height: auto;
  }

  .froms {
    max-width: revert;
    padding: 1.5rem 2.5rem 2rem;
  }

  .heading {
    margin: 2rem 0;
  }
}

@media (max-width: 530px) {
  main {
    padding: 1rem;
  }

  .box {
    border-radius: 2rem;
  }

  .inner-box {
    padding: 1rem;
  }

  .text-wrap {
    margin-bottom: 1rem;
  }

  .text-group h2 {
    font-size: 1.2rem;
  }

  .froms {
    padding: 1rem 2rem 1.5rem;
  }
}
