.smooth-toggle {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: opacity 2s ease, max-height 0.5s ease;
}

.smooth-toggle.show {
    opacity: 1;
    max-height: 1000px;
    /* Adjust based on content size */
}

.custom-blue-btn {
    background-color: rgb(0, 13, 107);
}