.header__top {
  padding: 10px 0px;
  background: #000d6b;
  color: #fff;
}

.header__top__left {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}

.header__top__left span {
  font-size: 0.9rem;
}

.header__top__help {
  display: flex;
  align-items: center;
  column-gap: 0.4rem;
}

.header__top__help i {
  background: #fff;
  color: #000d6b;
  padding: 2px 4px;
  border-radius: 50px;
  font-size: 0.7rem;
}

.header__top__right {
  text-decoration: none;
  color: #fff;
  font-size: 0.9rem;
}

.header__top__right a:hover {
  color: #fff;
}

/* ============ header middle style =========== */
.location-selector {
  display: flex;
  align-items: center;

}

.location-selector i {
  font-size: 18px;
  color: #fff;
  margin-right: 8px;
}

.location-selector select {
  border: none;
  outline: none;
  font-size: 16px;

  background-color: #000d6b;
  color: #fff;
  cursor: pointer;
  flex: 1;
}

.location-selector option {
  padding: 8px;
  background-color: #fff;
  color: #000d6b;
  font-size: 16px;
  /* Match the font size with the select element */
}

/* Optionally, add some styles for hover and focus */
.location-selector option:hover {
  background-color: #fff;
  color: #000d6b;
}

.location-selector select:focus option {
  background-color: #fff;
  color: #000d6b;
}


.header__middle {
  padding: 20px 0px;
}

.logo h1 a span {
  font-size: 1.5rem;
}

.logo h1 a i {
  font-size: 2.2rem;
}

.logo h1 a {
  text-decoration: none;
  color: #000d6b;
  font-weight: 700;
}

.logo h1 {
  line-height: 1.5rem;
}

.header__location span i {
  font-size: 2.2rem;
  color: #000d6b;
}

.header__location-content h4 {
  font-size: 1.2rem;
  font-weight: 700;
  color: #000d6b;
}

.header__location-content h6 {
  font-weight: 600;
}

.header__btn {
  background: #000d6b !important;
  padding: 10px 20px;
  border: none;
  outline: none;
  border-radius: 5px;
}

.header__btn a {
  display: flex;
  align-items: center;
  column-gap: 0.4rem;
  text-decoration: none;
  color: #fff;
  justify-content: end;
  font-size: 0.9rem;
}

.header__btn a:hover {
  color: #fff;
}

/* General Styles */
.link-wrapper {
  position: relative;
  display: inline-block;
}

.profile-link {
  display: flex;
  align-items: center;
  /* padding: 3px; */
  background-color: #000d6b;
  color: #fff;

  text-decoration: none;
  transition: background-color 0.3s ease, transform 0.3s ease;

}



.profile-link span {
  display: flex;
  align-items: center;
}

.profile-link i {
  margin-left: 8px;
  font-size: 1.2rem;
}

/* Button Container */
.button-container {
  display: block;
  position: absolute;
  top: 150%;
  left: 30%;
  background-color: #fff;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  min-width: 160px;
}

/* Button Styles */
.button-container .button {
  display: block;
  padding: 10px;
  color: #fff;
  text-decoration: none;
  border: none;
  border-radius: 3px;
  margin-bottom: 10px;
  background-color: #003366;
  text-align: center;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.button-container .button.sign-out {
  background-color: #f9a826;
}

.button-container .button:hover {
  background-color: #004494;
  /* Even darker blue */
  transform: scale(1.03);
}

.button-container .button.sign-out:hover {
  background-color: #f9a826;
  /* Darker red for sign out */
  transform: scale(1.03);
}


/* ================ main navbar styles =========== */

.main__navbar {
  padding: 15px 0;
  background: #000d6b;
  position: fixed;
  /* Fixed positioning */
  top: 0;
  /* Align to the top of the viewport */
  left: 0;
  /* Align to the left of the viewport */
  width: 100%;
  /* Ensure it spans the full width */
  z-index: 1000;
  /* Make sure it's above other content */
}


.mobile__menu i {
  color: #fff;
  font-size: 1.3rem;
  cursor: pointer;
}

.mobile__menu {
  display: none;
}

.menu {
  display: flex;
  align-items: center;
  column-gap: 2.7rem;

}

.nav__item {
  color: #fff;
  text-decoration: none;
  transition: 0.3s;
}

.nav__item:hover {
  color: #f9a826;
}

.search__box {
  border: 1px solid #7c8a972f;
  padding: 7px 12px;
  display: flex;
  align-items: center;
  border-radius: 50px;
  background: #020a4de9;
}

.search__box input {
  background: transparent;
  border: none;
  outline: none;
  color: #fff;
  cursor: pointer;
}

.search__box input::placeholder {
  font-size: 0.8rem;
}

.search__box span i {
  color: rgba(255, 255, 255, 0.555);
  cursor: pointer;
}

.nav__active {
  color: #f9a826;
}

/* styles.css */

.custom-hover {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  /* Adjust gap between text and icon */
  color: white;
  /* Default text color */
  text-decoration: none;
  /* No underline */
}

.custom-hover:hover {
  color: #f9a826;
  /* Changes text color to yellow on hover */
}



/* Dropdown Menu */
.dropdown-menu {
  position: absolute;
  top: 50px;
  /* Adjust based on your header */
  right: 0;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Shadow effect */
  padding: 10px;
  opacity: 0;
  /* Hidden by default */
  visibility: hidden;
  /* Ensure it's not interactive when hidden */
  transition: opacity 0.3s ease, visibility 0.3s ease;
  /* Smooth transition */
  z-index: 1000;
}

.dropdown-menu.show {
  opacity: 1;
  /* Visible */
  visibility: visible;
  /* Make it interactive */
}

.dropdown-item {
  padding: 10px;
  color: black;
  text-decoration: none;
  display: block;
}

.dropdown-item:hover {
  background-color: #f1f1f1;
  border-radius: 3px;
}

.profileimage {

  transition: filter 0.3s ease-in-out;
  transition: transform 250ms;
}

.profileimage:hover {

  filter: drop-shadow(0px 0px 4px rgb(255, 255, 255));
  transform: translateY(-3px);
}

.profileName {
  font-family: "Cedarville Cursive", cursive;
}




@media only screen and (max-width: 768px) {


  .mobile__menu {
    display: block;
  }


  .navigation {
    background: rgb(0, 13, 107, 0.6);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    display: none;
  }

  .menu {
    width: 250px;
    height: 100%;
    background: #fff;
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 2rem;
    justify-content: center;
  }

  .menu a {
    color: #000d6b;
    font-weight: 600;
    font-size: 0.9rem;
  }

  .menu__active {
    display: block;
  }





}