/* UserProfile.css */
.icon-edit-save,
button {
  transition: color 0.3s ease;
  color: #000d6b;

}

.icon-edit-save:hover,
.icon-edit-save:focus,
button {
  color: white;

}

.large-text {
  font-size: 1.1rem;
  /* Adjust the size as needed */
}

.profile-header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.profile-header .btn {
  display: flex;
  align-items: center;
}

.profile-header .btn i {
  margin-right: 5px;
}

/* Add your custom styles for tabs and dropdown here */
.nav-active {
  font-weight: bold;
  color: #007bff;
  /* Active tab color */
}

.nav-inactive {
  color: #6c757d;
  /* Inactive tab color */
}

/* Styles for dropdown items when active */
.dropdown-menu .active {
  background-color: #007bff;
  /* Adjust color as needed */
  color: white;
}

/* UserProfile.css */
.btn-primary {
  background-color: rgb(0, 13, 107) !important;
  border-color: rgb(0, 13, 107) !important;
}

.btn-primary:hover {
  background-color: rgb(0, 10, 90) !important;
  /* Darker shade on hover */
  border-color: rgb(0, 10, 90) !important;
}